import { Controller } from "@hotwired/stimulus"
import {enter, leave, toggle} from 'el-transition' // https://www.npmjs.com/package/el-transition


// Connects to data-controller="menu"
export default class extends Controller {
  static targets = [ "dropdownMenu" ];

  connect() {
    // console.log("Locale controller connected!");
  }

  toggleDropdown() {
    toggle(this.dropdownMenuTarget)
    // if (this.dropdownMenuTarget.classList.contains("hidden")) {
    //   this.openMenu();
    // } else {
    //   this.closeMenu();
    // }
  }

  openMenu() {
    enter(this.dropdownMenuTarget)
  }

  closeMenu() {
    leave(this.dropdownMenuTarget)
  }

}
