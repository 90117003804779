import { Controller } from "@hotwired/stimulus"
import * as d3 from "d3";
window.d3 = d3;

// Regular dice
class RegularDice {
  constructor(name, faces) {
    this.name = name;
    this.faces = faces; // number of faces
    this.averageMaxProbability = 1 / faces; // the average of the maximum probability of each face
  }

  roll() {
    return Math.floor(Math.random() * this.faces);
  }
}

class IrregularDice {
  constructor(name, probabilities) {
    this.name = name;
    this.faces = probabilities.length; // number of faces
    const sum = probabilities.reduce((partialSum, a) => partialSum + a, 0); // the sum of all probabilities
    const normalizedProbabilities = probabilities.map(a => a / sum); // the probabilities normalized to sum 1
    this.averageMaxProbability = Math.max(...normalizedProbabilities); // the average of the maximum probability of each face
    this.cummulativeProbabilities = normalizedProbabilities.map((a, i) => normalizedProbabilities.slice(0, i + 1).reduce((partialSum, a) => partialSum + a, 0)); // the cummulative probabilities
  }

  roll() {
    const random = Math.random(); // 0 <= random < 1
    for(let i = 0; i < this.cummulativeProbabilities.length; i++) {
      if(random <= this.cummulativeProbabilities[i]) {
        return i;
      }
    }
  }
}

// data = [{face: 1, count: 0}, {face: 2, count: 0}, {face: 3, count: 0}]
// d3.groupSort(data, ([d]) => -d.face, (d) => d.count)


class CountingResults {
  constructor(faces) { // pass the number of faces of the dice
    this.faces = faces;
    this.sum = 0;
    this.results = new Array(faces).fill(0);
  }

  increment(index) {
    this.results[index] += 1;
    this.sum += 1;
  }

  add(results) {
    for(let i = 0; i < this.faces; i++) {
      this.results[i] += results[i];
    }
    this.sum += results.reduce((partialSum, a) => partialSum + a, 0);
  }
}



// Connects to data-controller="dice"
export default class DiceController extends Controller {
  static targets = [ "section1", "section2", "section3", "section4", "graph", "polyDescription", "progressBarContainer", "progressBar", "progressPercentage", "progressinProgress", "progressCompleted" ]; 
  // static targets = [ "dice", "rolls", "start", "results", "graph", "progressBar", "progressPercentage", "progressinProgress", "progressCompleted" ];
  static dices = { d4: 4, d6: 6, d6i: 6, d8: 8, d12: 12, d20: 20 };
  static rolls = [ 100, 1_000, 10_000, 100_000, 1_000_000, 10_000_000 ];
  static defaultDice = "d6";
  static defaultRoll = 10_000;
  static INITIAL = 0
  static DICE_CHOSEN = 1
  static ROLLS_COUNT_CHOSEN = 2
  static IN_PROGRESS = 3
  static RESULTS = 4

  static d6iProbabilities = [19.12, 15.67, 12.14, 16.70, 16.06, 20.32];

  static checkedLabelClass = "bg-alberorosa-600 text-white hover:bg-alberorosa-700".split(" ");
  static notCheckedLabelClass = "ring-1 ring-inset ring-gray-300 bg-alberorosa-50 text-gray-900 hover:bg-alberorosa-100".split(" ");

  static fps = 25;
  static values = {
    d4: String,
    d6: String,
    d6i: String,
    d8: String,
    d12: String,
    d20: String,
    xaxis: String
  }


  detectPhase() {
    if (this.chosenDice == null) {
      this.current_phase = DiceController.INITIAL
    } else if (this.chosenRolls == null) {
      this.current_phase = DiceController.DICE_CHOSEN
    } else {
      this.current_phase = DiceController.ROLLS_COUNT_CHOSEN
    }
    console.log(this.current_phase);
  }


  connect() {
    this.current_phase = DiceController.INITIAL
    this.chosenDice = null
    this.chosenRolls = null
    this.detectPhase()
    this.phase_to_ui()
    this.svg = null
    this.xScale = null
    this.yScale = null

    // this.checkPerformance()
  }

  checkPerformance() {
    let t0;
    t0 = performance.now()
    let dice = this.getDice("d6");
    let results = new CountingResults(dice.faces);

    for(let i = 0; i < 10_000_000; i++) {
      results.increment(dice.roll());
    }
    console.log(`${performance.now() - t0} ms`);
    console.log(results);
  }

  initPlot(facesNumber, averageMaxDraws, chosenRolls) {
    let max;
    if (this.chosenRolls < 1000) {
      max = 2 * averageMaxDraws
    } else if (this.chosenRolls < 10000) {
      max = 1.25 * averageMaxDraws
    } else {
      max = 1.1 * averageMaxDraws
    }

    const data = new Array(facesNumber).fill(0).map((_, i) => ({face: i+1, count: 0}))
    // data = [{face: 1, count: 0}, {face: 2, count: 0}, {face: 3, count: 0}]


    // from https://observablehq.com/@d3/bar-chart/2
    
    
    // Declare the chart dimensions and margins.
    const chart  = { width: 928, height: 500 } 
    const margin = { top: 30, right: 20, bottom: 30, left: 20 }


    // Declare the x (horizontal position) scale.
    this.xScale = d3.scaleLinear()
        .domain([0, max])
        .range([margin.left, chart.width - margin.right]);
    const xScale = this.xScale


    // Declare the y (vertical position) scale.
    this.yScale = d3.scaleBand()
        .domain(d3.map(data, (d) => facesNumber-d.face+1))
        // .domain(d3.groupSort(data, ([d]) => -d.face, (d) => d.count)) // descending frequency
        .range([chart.height - margin.bottom, margin.top])
        .padding(0.3);
    const yScale = this.yScale
    

    // Create the SVG container.
    this.svg = d3.create("svg")
        .attr("width", chart.width)
        .attr("height", chart.height)
        .attr("viewBox", [0, 0, chart.width, chart.height])
        .attr("style", "max-width: 100%; height: auto;");

    // Add a rect for each bar.
    const bars = this.svg.append("g")
      .attr("fill", "#e738ab") // "#6366f1") 818cf8 
      .selectAll()
      .data(data)


    bars.join("rect")
      .attr("x", (d) => xScale(0))
      .attr("y", (d) => yScale(d.face))
      .attr("height", yScale.bandwidth())
      .attr("width", (d) => xScale(d.count) - xScale(0));


    // Add the x-axis and label.
    this.svg.append("g")
        .attr("transform", `translate(0,${chart.height - margin.bottom})`)
        .call(d3.axisBottom(xScale))
        .call(g => g.append("text")
            .attr("x", chart.width - margin.left - margin.right + 30)
            .attr("y", -4)
            .attr("fill", "currentColor")
            .attr("text-anchor", "end")
            .text(this.xaxisValue));


    // Add the y-axis and label, and remove the domain line.
    this.svg.append("g")
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(yScale).tickSizeOuter(0))
        .call(g => g.select(".domain").remove())

    
    bars.join('text')
      .attr('text-anchor', 'start')
      .attr('class', 'values')
      // .attr("x", (d) => xScale(0)+20)
      .attr("x", (d) => xScale(0)+20)
      .attr("y", (d) => yScale(d.face) + yScale.bandwidth()/2)
      .attr("fill", this.textColor())
      .attr('dy', '0.35em')
      .attr("font-size", "0.7rem")
      .text(d => d.count )


    // Return the SVG element.
    this.graphTarget.append(this.svg.node());
  }

  
  textColor() {
    if (document.documentElement.classList.contains('light')) {
      return "#e738ab";
    } else {
      return "#f274cb";
    }
  }



  updatePlot(data) {
    // https://observablehq.com/@uvizlab/d3-tutorial-4-bar-chart-with-transition
    // Update all rects
    this.svg.selectAll("rect")
      .data(data)
      .transition() // <---- Here is the transition
      .duration(5) // 5 ms
      // .attr("x", (d) => this.xScale(0))
      .attr("width", (d) => this.xScale(d.count) - this.xScale(0));

    this.svg.selectAll(".values")
      .data(data)
      .attr("x", (d) => this.xScale(d.count) - this.xScale(0) + 25)
      .text(d => d.count.toLocaleString() )
      // .text(d => d3.format("")(d.count) )

  }

  
  experimentD3One() {
    // from https://observablehq.com/@d3/bar-chart/2
    
    const data = [{"letter":"A","frequency":0.08167},{"letter":"B","frequency":0.01492},{"letter":"C","frequency":0.02782},{"letter":"D","frequency":0.04253},{"letter":"E","frequency":0.12702},{"letter":"F","frequency":0.02288},{"letter":"G","frequency":0.02015},{"letter":"H","frequency":0.06094},{"letter":"I","frequency":0.06966},{"letter":"J","frequency":0.00153},{"letter":"K","frequency":0.00772},{"letter":"L","frequency":0.04025},{"letter":"M","frequency":0.02406},{"letter":"N","frequency":0.06749},{"letter":"O","frequency":0.07507},{"letter":"P","frequency":0.01929},{"letter":"Q","frequency":0.00095},{"letter":"R","frequency":0.05987},{"letter":"S","frequency":0.06327},{"letter":"T","frequency":0.09056},{"letter":"U","frequency":0.02758},{"letter":"V","frequency":0.00978},{"letter":"W","frequency":0.0236},{"letter":"X","frequency":0.0015},{"letter":"Y","frequency":0.01974},{"letter":"Z","frequency":0.00074}]
    
    // Declare the chart dimensions and margins.
    const chart = { width: 928, height: 500 } 
    const margin = { top: 30, right: 0, bottom: 30, left: 40 }

    // Declare the x (horizontal position) scale.
    const x = d3.scaleBand()
        .domain(d3.groupSort(data, ([d]) => -d.frequency, (d) => d.letter)) // descending frequency
        .range([margin.left, chart.width - margin.right])
        .padding(0.1);
    
    // Declare the y (vertical position) scale.
    const y = d3.scaleLinear()
        .domain([0, d3.max(data, (d) => d.frequency)])
        .range([chart.height - margin.bottom, margin.top]);

    // Create the SVG container.
    const svg = d3.create("svg")
        .attr("width", chart.width)
        .attr("height", chart.height)
        .attr("viewBox", [0, 0, chart.width, chart.height])
        .attr("style", "max-width: 100%; height: auto;");

    // Add a rect for each bar.
    svg.append("g")
        .attr("fill", "steelblue")
      .selectAll()
      .data(data)
      .join("rect")
        .attr("x", (d) => x(d.letter))
        .attr("y", (d) => y(d.frequency))
        .attr("height", (d) => y(0) - y(d.frequency))
        .attr("width", x.bandwidth());

    // Add the x-axis and label.
    svg.append("g")
        .attr("transform", `translate(0,${chart.height - margin.bottom})`)
        .call(d3.axisBottom(x).tickSizeOuter(0));

    // Add the y-axis and label, and remove the domain line.
    svg.append("g")
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y).tickFormat((y) => (y * 100).toFixed()))
        .call(g => g.select(".domain").remove())
        .call(g => g.append("text")
            .attr("x", -margin.left)
            .attr("y", 10)
            .attr("fill", "currentColor")
            .attr("text-anchor", "start")
            .text("↑ Frequency (%)"));

    // Return the SVG element.
    // this.graphTarget.append(svg.node());
    this.graphTarget.innerHTML = svg.node();
  }


  getDice(name) {
    switch (name) {
      case "d4":
        return new RegularDice(name, 4);
      case "d6":
        return new RegularDice(name, 6);
      case "d6i":
        return new IrregularDice(name, DiceController.d6iProbabilities);
      case "d8":
        return new RegularDice(name, 8);
      case "d12":
        return new RegularDice(name, 12);
      case "d20":
        return new RegularDice(name, 20);
      default:
        return new RegularDice("d6", 6);
    }
  }


  // Click on a dice
  diceChosen(event) {
    this.chosenDice = event.target.value
    const labelElem = event.target.parentElement
    labelElem.classList.remove(...DiceController.notCheckedLabelClass)
    labelElem.classList.add(...DiceController.checkedLabelClass)

    Object.keys(DiceController.dices).forEach(dice => {
      if(dice != this.chosenDice) {
        const el = this.section1Target.querySelector(`input[value="${dice}"]`).parentElement
        el.classList.remove(...DiceController.checkedLabelClass)
        el.classList.add(...DiceController.notCheckedLabelClass)
      }
    })
    console.log(this.chosenDice);
    switch (this.chosenDice) {
      case "d4":
        this.polyDescriptionTarget.innerHTML = this.d4Value;
        break;
      case "d6":
        this.polyDescriptionTarget.innerHTML = this.d6Value;
        break;
      case "d6i":
        this.polyDescriptionTarget.innerHTML = this.d6iValue;
        break;
      case "d8":
        this.polyDescriptionTarget.innerHTML = this.d8Value;
        break;
      case "d12":
        this.polyDescriptionTarget.innerHTML = this.d12Value;
        break;
      case "d20":
        this.polyDescriptionTarget.innerHTML = this.d20Value;
        break;
    }
    this.detectPhase()
    // this.current_phase = DiceController.DICE_CHOSEN
    this.phase_to_ui()
  }


  rollsChosen(event) {
    this.chosenRolls = parseInt(event.target.value)
    const labelElem = event.target.parentElement
    labelElem.classList.remove(...DiceController.notCheckedLabelClass)
    labelElem.classList.add(...DiceController.checkedLabelClass)

    DiceController.rolls.forEach(r => {
      if(r != this.chosenRolls) {
        const el = this.section2Target.querySelector(`input[value="${r}"]`).parentElement
        el.classList.remove(...DiceController.checkedLabelClass)
        el.classList.add(...DiceController.notCheckedLabelClass)
      }
    })
    this.detectPhase()
    // this.current_phase = DiceController.ROLLS_COUNT_CHOSEN
    this.phase_to_ui()
  }


  runGame() {
    this.current_phase = DiceController.IN_PROGRESS
    this.progressBarTarget.style.width = "0%"
    this.progressBarContainerTarget.classList.remove("hidden");
    this.phase_to_ui()

    const totTime = Math.log10(this.chosenRolls) / 2 * 1000 // in ms
    const frames  = Math.ceil(totTime * DiceController.fps / 1000)
    const rollsPerFrame = Math.floor(this.chosenRolls / frames)
    
    let dice = this.getDice(this.chosenDice);
    let results = new CountingResults(dice.faces);

    if (this.svg) { this.svg.remove() }
    this.progressinProgressTarget.classList.remove("hidden")
    this.progressCompletedTarget.classList.add("hidden")
    
    this.initPlot(dice.faces, dice.averageMaxProbability * this.chosenRolls, this.chosenRolls)
    this.rolling(dice, results, this.chosenRolls, rollsPerFrame)
    this.section4Target.scrollIntoView({ 
      behavior: 'smooth'
    });
  }


  rolling(dice, results, chosenRolls, rollsPerFrame) {
    if (results.sum < chosenRolls) {
      const t0 = performance.now()
      for(let i = 0; i < Math.min(chosenRolls-results.sum, rollsPerFrame); i++) {
        results.increment(dice.roll());
      }
      const perc = Math.round(results.sum / chosenRolls * 1000) / 10
      this.progressBarTarget.style.width = `${perc}%`

      // data = [{face: 1, count: 0}, {face: 2, count: 0}, ...]
      this.updatePlot(results.results.map((count, face) => ({face: face+1, count: count})));

      this.progressPercentageTarget.innerHTML = `${perc}%`

      const residualTime = Math.max(1000 / DiceController.fps - (performance.now() - t0), 0)
      // const residualTime = 0;
      setTimeout(() => {
          this.rolling(dice, results, chosenRolls, rollsPerFrame)
        }
        , residualTime)
    } else { // rolls finished!
      this.current_phase = DiceController.RESULTS
      this.progressinProgressTarget.classList.add("hidden")
      this.progressCompletedTarget.classList.remove("hidden")
      this.phase_to_ui()

      setTimeout(() => {
        this.progressBarContainerTarget.classList.add("hidden");
      }
      , 700)
    }
  }



  phase_to_ui() {
    if (this.current_phase == DiceController.INITIAL) {
      this.showSection1(true);
      this.showSection2(false);
      this.showSection3(false);
      this.showSection4(false);
    }
    else if (this.current_phase == DiceController.DICE_CHOSEN) {
      this.showSection1(true);
      this.showSection2(true);
      this.showSection3(false);
      this.showSection4(false);
    }
    else if (this.current_phase == DiceController.ROLLS_COUNT_CHOSEN) {
      this.showSection1(true);
      this.showSection2(true);
      this.showSection3(true);
      this.showSection4(false);
   }
    else if (this.current_phase == DiceController.IN_PROGRESS) {
      // calculation in progress
      this.showSection1(true);
      this.showSection2(true);
      this.showSection3(false);
      this.showSection4(true);
      this.section3Target.classList.add("pointer-events-none");
    }
    else if (this.current_phase == DiceController.RESULTS) {
      // showing results
      this.showSection1(true);
      this.showSection2(true);
      this.showSection3(true);
      this.showSection4(true);
      this.section3Target.classList.remove("pointer-events-none");
    }
  }


  // choose what to bet
  showSection1(show) {
    if (show) {
      this.section1Target.classList.remove("blur-sm");
    } else {
      this.section1Target.classList.add("blur-sm")
    }
  }


  // choose how much to bet
  showSection2(show) {
    if (show) {
      this.section2Target.classList.remove("blur-sm");
      this.section2Target.classList.remove("pointer-events-none");
    } else {
      this.section2Target.classList.add("blur-sm")
      this.section2Target.classList.add("pointer-events-none")
    }
  }

  // start button
  showSection3(show) {
    if (show) {
      this.section3Target.classList.remove("blur-sm");
      this.section3Target.classList.remove("pointer-events-none");
    } else {
      this.section3Target.classList.add("blur-sm")
      this.section3Target.classList.add("pointer-events-none");
    }
  }

  // result section
  showSection4(show) {
    if (show) {
      this.section4Target.classList.remove("invisible");
    } else {
      this.section4Target.classList.add("invisible")
    }
  }

  
  // const rollsKeys = Object.keys(this.constructor.rolls);
  // console.log(rollsKeys);

}

