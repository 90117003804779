import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dark"
export default class extends Controller {
  // static targets = [ "sun", "moon" ];

  connect() {
    this.foo();
  }

  toggleMode() {
    const c = document.documentElement.classList;
    if (c.contains('light')) {
      localStorage.setItem('theme','dark');
    } else {
      localStorage.setItem('theme','light');
    }
    this.foo();
  }

  foo() {
    try {
      var d = document.documentElement;
      var c = d.classList;
      c.remove('light','dark');
      var e=localStorage.getItem('theme');
      if('system'===e||(!e&&true)) {
          var t = '(prefers-color-scheme: dark)';
          var m = window.matchMedia(t);
          if(m.media!==t||m.matches) {
              d.style.colorScheme = 'dark';
              c.add('dark')
          } else {
              d.style.colorScheme = 'light';
              c.add('light')
          }
      } else if(e) {
          c.add(e || '')
      } if (e==='light'||e==='dark') d.style.colorScheme=e;            
    } catch (e) {}

  }
}

