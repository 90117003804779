import { Controller } from "@hotwired/stimulus"
import * as d3 from "d3";

class Round {
  static americanNumbers = ["00"].concat([...Array(37).keys()].map((n) => n.toString()))
  static frenchNumbers = [...Array(37).keys()].map((n) => n.toString())
  static numbers = [...Array(36).keys()].map((n) => n+1)
  static blacks = [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 25]
  static reds   = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36]

  constructor(version = "american") {
    this.version = version
    this.rolled = null    
    if (version == "american") {
      this.rolled = Round.americanNumbers[Math.floor(Math.random() * Round.americanNumbers.length)]
    } else {
      this.rolled = Round.frenchNumbers[Math.floor(Math.random() * Round.frenchNumbers.length)]
    }
    this.number = this.number()
  }

  isZero() {
    return this.rolled == "0"
  }
  isZeroZero() {
    return this.rolled == "00"
  }
  isNumber() {
    return !this.isZero() && !this.isZeroZero()
  }
  number() {
    if (this.isNumber()) {
      return parseInt(this.rolled)
    } else {
      return null
    }
  }

  isBlack() {
    if (this.isNumber) {
      return Round.blacks.includes(this.number);
    } else {
      return false;
    }
  }

  isRed() {
    if (this.isNumber) {
      return Round.reds.includes(this.number);
    } else {
      return false;
    }
  }

  is1stColumn() {
    if (this.isNumber) {
      return this.number%3==1;
    } else {
      return false;
    }
  }

  is2ndColumn() {
    if (this.isNumber) {
      return this.number%3==2;
    } else {
      return false;
    }
  }

  is3rdColumn() {
    if (this.isNumber) {
      return this.number%3==0;
    } else {
      return false;
    }
  }

}

// Connects to data-controller="roulette"
export default class RouletteController extends Controller {
  static targets = [ "section1", "section11", "section2", "section3", "section4", "graph", "progressBar", "progressPercentage", "progressinProgress", "progressCompleted", "resultsComments" ]; 

  static checkedLabelClass = "bg-alberorosa-600 text-white hover:bg-alberorosa-700".split(" ");
  static notCheckedLabelClass = "ring-1 ring-inset ring-gray-300 bg-alberorosa-50 text-gray-900 hover:bg-alberorosa-100".split(" ");
  static names = [ "straight_up", "black", "red", "1st_column", "2nd_column", "3rd_column" ]
  static numbers = Round.americanNumbers
  static amounts = [ 10, 20, 50, 100, 200, 500 ]
  static INITIAL = 0
  static WHERE_CHOSEN = 1
  static NUMBER_CHOSEN = 2
  static HOW_MUCH_CHOSEN = 3
  static IN_PROGRESS = 4
  static RESULTS = 5
  static values = {
    xaxis: String,
    yaxis: String
  }

  connect() {
    console.log("roulette connected")
    this.svg = null
    this.initialAmount = 1000
    this.amount = this.initialAmount
    this.chosenBetname = null
    this.chosenNumber = null
    this.bet_amount = null
    this.detectPhase()
  }


  detectPhase() {
    // console.log("chosenBetname: ", this.chosenBetname);
    if (this.chosenBetname == null) {
      this.current_phase = RouletteController.INITIAL
    } else {
      if (this.chosenBetname == "straight_up") {
        if (this.chosenNumber == null) {
          this.current_phase = RouletteController.WHERE_CHOSEN
        } else {
          if (this.bet_amount == null) {
            this.current_phase = RouletteController.NUMBER_CHOSEN
          } else {
            this.current_phase = RouletteController.HOW_MUCH_CHOSEN
          }
        }
      } else {
        if (this.bet_amount == null) {
          this.current_phase = RouletteController.WHERE_CHOSEN
        } else {
          this.current_phase = RouletteController.HOW_MUCH_CHOSEN
        }
      }
    }
    console.log(this.current_phase);
    this.phase_to_ui()
  }


  runGame() {
    this.current_phase = RouletteController.IN_PROGRESS
    this.phase_to_ui()
    if (this.svg) { this.svg.remove() }

    // check we have all the data, valid data!
    this.amount = this.initialAmount
    this.bet_amount = parseInt(this.bet_amount)
    const amounts = []
    amounts.push(this.amount)

    while(this.amount > 0) {
      this.playRound(Math.min(this.amount, this.bet_amount))
      // this.progressPercentageTarget.innerText = `${Math.round(this.amount/this.initialAmount*100)}%`
      // console.log("amount: ", this.amount);
      amounts.push(this.amount)
    }
    console.log("count: ", amounts.length)
    console.log("amounts: ", amounts);


    this.initPlot(amounts);

    const duration = 2500; // 2.5 seconds
    const timeSteps = Math.min(duration / amounts.length, 100);

    this.updateAnimation(amounts, 0, timeSteps);

    this.section4Target.scrollIntoView({ 
      behavior: 'smooth'
    });
  }

  updateAnimation(amounts, n, timeSteps) {
    setTimeout(() => {
      this.updatePlot(amounts, n);
      if (n < amounts.length) {
        this.updateAnimation(amounts, n+1, timeSteps);
      } else {
        this.current_phase = RouletteController.RESULTS
        this.phase_to_ui()

      }
    }, timeSteps);
  }



  updatePlot(amounts, n) {
    const data = amounts.slice(n,n+2).map((v, i) => { return { x: i+n, y: v } })

    // Declare the line generator.
    const line = d3.line((d) => this.xScale(d.x), (d) => this.yScale(d.y));
    // https://d3js.org/d3-shape/line

    this.svg.append("path")
      .attr("fill", "none")
      .attr("stroke", "#e738ab")
      .attr("stroke-width", 1.5)
      .attr("d", line(data));
  }



  initPlot(amounts) {
    const size = amounts.length
    const max = Math.max(...amounts)
    const data = amounts.map((v, i) => { return { x: i, y: v } })
    // console.log("data: ", data);

    const chart  = { width: 928, height: 500 } 
    const margin = { top: 30, right: 0, bottom: 30, left: 40 }

    const xScale = d3.scaleLinear([0, Math.ceil((size-1)*1.05)], [margin.left, chart.width - margin.right]);
    const yScale = d3.scaleLinear([0, max], [chart.height - margin.bottom, margin.top]);


    // Create the SVG container.
    const svg = d3.create("svg")
        .attr("width", chart.width)
        .attr("height", chart.height)
        .attr("viewBox", [0, 0, chart.width, chart.height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

    // Declare the line generator.
    // const line = d3.line((d) => xScale(d.x), (d) => yScale(d.y));
    // https://d3js.org/d3-shape/line

    // Add the x-axis.
    svg.append("g")
        .attr("transform", `translate(0,${chart.height - margin.bottom})`)
        .call(d3.axisBottom(xScale).ticks(chart.width / 80).tickSizeOuter(0))
        .call(g => g.append("text")
            .attr("x", chart.width - margin.left - margin.right + 30)
            .attr("y", -4)
            .attr("fill", "currentColor")
            .attr("text-anchor", "end")
            .text(this.xaxisValue));

    // Add the y-axis, remove the domain line, add grid lines and a label.
    svg.append("g")
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(yScale).ticks(chart.height / 40))
        .call(g => g.select(".domain").remove())
        .call(g => g.selectAll(".tick line").clone()
            .attr("x2", chart.width - margin.left - margin.right)
            .attr("stroke-opacity", 0.1))
        .call(g => g.append("text")
            .attr("x", -margin.left)
            .attr("y", 10)
            .attr("fill", "currentColor")
            .attr("text-anchor", "start")
            .text(this.yaxisValue));

      // Append a path for the line.
      // svg.append("path")
      //     .attr("fill", "none")
      //     .attr("stroke", "steelblue")
      //     .attr("stroke-width", 1.5)
      //     .attr("d", line(data));

          // Return the SVG element.
      this.graphTarget.append(svg.node());
      this.svg = svg
      this.xScale = xScale
      this.yScale = yScale
  }



  playRound() {
    const round = new Round();

    switch(this.chosenBetname) {
      case "black":
        if (round.isBlack()) {
          this.amount += this.bet_amount
        } else {
          this.amount -= this.bet_amount
        }
        break;
      case "red":
        if (round.isRed()) {
          this.amount += this.bet_amount
        } else {
          this.amount -= this.bet_amount
        }
        break;
      case "1st_column":
        if (round.is1stColumn()) {
          this.amount += this.bet_amount * 2
        } else {
          this.amount -= this.bet_amount
        }
        break;
      case "2nd_column":
        if (round.is2ndColumn()) {
          this.amount += this.bet_amount * 2
        } else {
          this.amount -= this.bet_amount
        }
        break;
      case "3rd_column":
        if (round.is3rdColumn()) {
          this.amount += this.bet_amount * 2
        } else {
          this.amount -= this.bet_amount
        }
        break;
      case "straight_up":
        if (round.rolled == this.chosenNumber) {
          this.amount += this.bet_amount * 35
        } else {
          this.amount -= this.bet_amount
        }
        break;
      }
  }



  betnameChosen(event) {
    console.log("betnameChosen");

    const chosen = event.target
    this.chosenBetname = chosen.value
    this.ui_check_radiobutton(chosen, RouletteController.names);

    console.log(this.chosenBetname);
    if (this.chosenBetname == "straight_up") {
        this.showSection11(true);
    } else {
        this.showSection11(false);
    }
    // this.current_phase = RouletteController.WHERE_CHOSEN
    // this.bet_amount = null
    this.detectPhase()
  }


  numberChosen(event) {
    const chosen = event.target
    this.chosenNumber = chosen.value
    this.ui_check_radiobutton(chosen, RouletteController.numbers);
    this.detectPhase()
  }


  amountChosen(event) {
    const chosen = event.target
    this.bet_amount = chosen.value
    this.ui_check_radiobutton(chosen, RouletteController.amounts);
    
    this.detectPhase()
  }

  
  ui_check_radiobutton(chosen, list) {
    const labelElem = chosen.parentElement
    labelElem.classList.remove(...RouletteController.notCheckedLabelClass)
    labelElem.classList.add(...RouletteController.checkedLabelClass)

    const filedset = labelElem.closest("fieldset")
    list.forEach(v => {
      if(v != chosen.value) {
        const el = filedset.querySelector(`input[value="${v}"]`).parentElement
        el.classList.remove(...RouletteController.checkedLabelClass)
        el.classList.add(...RouletteController.notCheckedLabelClass)
      }
    })
  }


  phase_to_ui() {
    if (this.current_phase == RouletteController.INITIAL) {
      this.showSection1(true);
      this.showSection11(false);
      this.showSection2(false);
      this.showSection3(false);
      this.showSection4(false);
    }
    else if (this.current_phase == RouletteController.WHERE_CHOSEN) {
      this.showSection1(true);
      this.showSection2(true);
      this.showSection3(false);
      this.showSection4(false);
    }
    else if (this.current_phase == RouletteController.NUMBER_CHOSEN) {
      this.showSection1(true);
      this.showSection2(true);
      this.showSection3(true);
      this.showSection4(false);
   }
    else if (this.current_phase == RouletteController.HOW_MUCH_CHOSEN) {
      this.showSection1(true);
      this.showSection2(true);
      this.showSection3(true);
      this.showSection4(false);
    }
    else if (this.current_phase == RouletteController.IN_PROGRESS) {
      this.showSection1(true);
      this.showSection2(true);
      this.showSection3(false);
      this.showSection4(true);
      this.resultsCommentsTarget.classList.add("hidden")
      this.section3Target.classList.add("pointer-events-none");
    }
    else if (this.current_phase == RouletteController.RESULTS) {
      this.showSection1(true);
      this.showSection2(true);
      this.showSection3(true);
      this.showSection4(true);
      this.resultsCommentsTarget.classList.remove("hidden")
      this.section3Target.classList.remove("pointer-events-none");
    }
  }

  // choose what to bet
  showSection1(show) {
    if (show) {
      this.section1Target.classList.remove("blur-sm");
    } else {
      this.section1Target.classList.add("blur-sm")
    }
  }

  showSection11(show) {
    if (show) {
      this.section11Target.classList.remove("hidden");
    } else {
      this.section11Target.classList.add("hidden")
    }
  }

  // choose how much to bet
  showSection2(show) {
    if (show) {
      this.section2Target.classList.remove("blur-sm");
      this.section2Target.classList.remove("pointer-events-none");
    } else {
      this.section2Target.classList.add("blur-sm")
      this.section2Target.classList.add("pointer-events-none")
    }
  }

  // start button
  showSection3(show) {
    if (show) {
      this.section3Target.classList.remove("blur-sm");
      this.section3Target.classList.remove("pointer-events-none");
    } else {
      this.section3Target.classList.add("blur-sm")
      this.section3Target.classList.add("pointer-events-none");
    }
  }

  // result section
  showSection4(show) {
    if (show) {
      this.section4Target.classList.remove("invisible");
    } else {
      this.section4Target.classList.add("invisible")
    }
  }
}

