import { Controller } from "@hotwired/stimulus"
import * as d3 from "d3";
import {largestTriangleThreeBucket} from '@d3fc/d3fc-sample';


class Coin {
  flip() {
    return Math.random() > 0.5 ? 1 : -1
  }
}

class Tosses {
  constructor(count) {
    this.count = count
    this.dataSize = count
    this.position = 0
    this.array = []
    this.cumulative = [0]
    this.coin = new Coin()
    this.data = []
  }

  run() {
    let c = this.count
    let i = 0
    while(c > 0) {
      const flip = this.coin.flip()
      this.position += flip
      this.array.push(flip)
      this.cumulative.push(this.position) // [1,4,2,-1,5].reduce((s,n) => s+n, 0)
      c -= 1
    }

      const data = this.cumulative.reduce((acc, curr, idx) => {
          acc.push({x: curr, y: idx});
          return acc;
        }, []);

      if (this.count>500) {
      const bucketSize = Math.ceil(this.count / 500)

      // Create the sampler
      const sampler = largestTriangleThreeBucket(); // https://github.com/d3fc/d3fc/blob/master/packages/d3fc-sample/README.md

      console.log(data);


      // Configure the x / y value accessors
      sampler.x(d => d.y).y(d => d.x); // invertiti!

      // Configure the size of the buckets used to downsample the data.
      sampler.bucketSize(bucketSize);

      // Run the sampler
      this.data = sampler(data);
      this.dataSize = this.data.length

    } else {
      this.data = data;
    }

  }
}

// Connects to data-controller="walk"
export default class WalkController extends Controller {
  // static targets = [ "sun", "moon" ];
  static targets = [ "section1", "section3", "section4", "graph", "progressBar", "progressPercentage", "progressinProgress", "progressCompleted" ]; 
  static checkedLabelClass = "bg-alberorosa-600 text-white hover:bg-alberorosa-700".split(" ");
  static notCheckedLabelClass = "ring-1 ring-inset ring-gray-300 bg-alberorosa-50 text-gray-900 hover:bg-alberorosa-100".split(" ");
  static availableSteps = [ 30, 100, 300, 1_000, 3_000, 10_000 ]
  static values = {
    xaxis: String,
    yaxis: String,
    lampurl: String,
    drunkurl: String
  }
  static INITIAL = 0
  static HOW_MANY_STEPS = 1
  static IN_PROGRESS = 2
  static RESULTS = 3



  connect() {
    this.current_phase = this.constructor.INITIAL
    this.chosenSteps = null
    this.phase_to_ui()
  }


  stepsChosen(event) {
    this.steps = event.detail.steps

    const chosen = event.target
    this.chosenSteps = chosen.value
    this.ui_check_radiobutton(chosen, WalkController.availableSteps);

    console.log(this.chosenBetname);
    this.current_phase = WalkController.HOW_MANY_STEPS
    this.bet_amount = null
    this.phase_to_ui()
  }


  ui_check_radiobutton(chosen, list) {
    const labelElem = chosen.parentElement
    labelElem.classList.remove(...WalkController.notCheckedLabelClass)
    labelElem.classList.add(...WalkController.checkedLabelClass)

    const filedset = labelElem.closest("fieldset")
    list.forEach(v => {
      if(v != chosen.value) {
        const el = filedset.querySelector(`input[value="${v}"]`).parentElement
        el.classList.remove(...WalkController.checkedLabelClass)
        el.classList.add(...WalkController.notCheckedLabelClass)
      }
    })
  }


  runGame() {
    this.current_phase = WalkController.IN_PROGRESS
    this.phase_to_ui()
    if (this.svg) { this.svg.remove() }

    // TODO: check we have all the data, valid data!
    let count = parseInt(this.chosenSteps)
    const position = []
    const tosses = new Tosses(count)
    tosses.run()

    this.tosses = tosses;
    this.initPlot();

    const duration = 2500; // 2.5 seconds
    const timeSteps = Math.min(duration / tosses.dataSize, 100);
    console.log("timeSteps: ", timeSteps);

    this.updateAnimation(0, timeSteps);

    this.section4Target.scrollIntoView({ 
      behavior: 'smooth'
    });
  }

  updateAnimation(n, timeSteps) {
    setTimeout(() => {
      this.updatePlot(n);
      if (n < this.tosses.dataSize) {
        this.updateAnimation(n+1, timeSteps);
      } else {
      this.current_phase = WalkController.RESULTS
      this.phase_to_ui()
      }
    }, timeSteps);
  }

  updatePlot(n) {
    const data = this.tosses.data.slice(n,n+2);

    // Declare the line generator.
    const line = d3.line((d) => this.xScale(d.x), (d) => this.yScale(d.y));

    // https://d3js.org/d3-shape/line

    this.svg.append("path")
        .attr("fill", "none")
        .attr("stroke", "#e738ab")
        .attr("stroke-width", 4)
        .attr("d", line(data));

    if (data.length>1) { // the last element has only a single point
      // move the lamppost downwards
      this.svg.select("#lamp")
          .attr("x", this.xScale(0) - 50)
          .attr("y", this.yScale(data[1].y) - this.margin.top+10)
          .raise(); // bring drunk to front!

      // move the drunk downwards
      this.svg.select("#drunk")
          .attr("x", this.xScale(data[1].x) - this.drunk.w * this.drunk.scale / 2)
          .attr("y", this.yScale(data[1].y) - this.drunk.h * this.drunk.scale)
          .raise(); // bring drunk to front!

      // move the x-axis downwards too
      this.svg.select("#xaxis")
          .attr("transform", `translate(0,${this.yScale(data[1].y)})`)
    }
  }

  centerLineColor() {
    if (document.documentElement.classList.contains('light')) {
      return "black";
    } else {
      return "#cccccc";
    }
  }


  initPlot() {
    const size    = this.tosses.count
    const abs_max = this.tosses.count  // Math.max(...tosses.array.map(Math.abs))

    const chart  = { width: 1000, height: 800 } 
    const margin = { top: 100, right: 40, bottom: 30, left: 40 }

    const xScale = d3.scaleLinear([-abs_max, abs_max], [margin.left, chart.width - margin.right]);
    const yScale = d3.scaleLinear([0, Math.ceil((size-1)*1.05)], [margin.top, chart.height - margin.bottom]);

    // Create the SVG container.
    const svg = d3.create("svg")
        .attr("width", chart.width)
        .attr("height", chart.height)
        .attr("viewBox", [0, 0, chart.width, chart.height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

    // Add the x-axis.
    const gx = svg.append("g")
        .attr("id", "xaxis")
        .attr("transform", `translate(0,${margin.top})`)
        .call(d3.axisTop(xScale).ticks(chart.width / 80).tickSizeOuter(0))
        .call(g => g.append("text")
            .attr("x", chart.width - margin.left - margin.right + 30)
            .attr("y", 16)
            .attr("fill", "currentColor")
            .attr("text-anchor", "end")
            .text(this.yaxisValue)); // "posizione"

    // Add the y-axis, remove the domain line, add grid lines and a label.
    const gy = svg.append("g")
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(yScale).ticks(chart.height / 40))
        .call(g => g.select(".domain").remove())
        .call(g => g.selectAll(".tick line").clone()
            .attr("x2", chart.width - margin.left - margin.right)
            .attr("stroke-opacity", 0.1))
        .call(g => g.append("text")
            .attr("x", -margin.left)
            .attr("y", 60)
            .attr("fill", "currentColor")
            .attr("text-anchor", "start")
            .text(this.xaxisValue));

      // linea rossa, obliqua, da destra a sinistra
      svg.append("line")
          .attr("x1", xScale(0))
          .attr("y1", yScale(0))
          .attr("x2", xScale(abs_max))
          .attr("y2", yScale(size))
          .attr("stroke", "#da3f32")
          .attr("stroke-width", 1.5)
          .attr("stroke-dasharray", "10");

      // linea rossa, obliqua, da sinistra a destra
      svg.append("line")
          .attr("x1", xScale(0))
          .attr("y1", yScale(0))
          .attr("x2", xScale(-abs_max))
          .attr("y2", yScale(size))
          .attr("stroke", "#da3f32")
          .attr("stroke-width", 1.5)
          .attr("stroke-dasharray", "10");

      // linea verticale e centrale
      svg.append("line")
          .attr("x1", xScale(0))
          .attr("y1", yScale(0))
          .attr("x2", xScale(0))
          .attr("y2", chart.height - margin.bottom)
          .attr("stroke", this.centerLineColor())
          .attr("stroke-width", 0.5)
          .attr("stroke-dasharray", "10");

      // lampione
      svg.append("svg:image")
          .attr("xlink:href", this.lampurlValue)
          .attr("id", "lamp")
          .attr("x", xScale(0) - 50)
          .attr("y", yScale(0) - margin.top+10)
          .attr("width", "100")
          .attr("height", margin.top - 10);

      // l'ubriaco
      const drunk = { w: 69, h: 92, scale: 0.4 }
      svg.append("svg:image")
          .attr("xlink:href", this.drunkurlValue ) // 69 x 92
          .attr("id", "drunk")
          .attr("x", xScale(0) - drunk.w * drunk.scale / 2)
          .attr("y", yScale(0) - drunk.h * drunk.scale)
          .attr("width", drunk.w * drunk.scale)
          .attr("height", drunk.h * drunk.scale);

          // Return the SVG element.
      this.graphTarget.append(svg.node());
      this.svg = svg
      this.xScale = xScale
      this.yScale = yScale
      this.drunk = drunk
      this.margin = margin
  }





  phase_to_ui() {
    if (this.current_phase == WalkController.INITIAL) {
      this.showSection1(true);
      this.showSection3(false);
      this.showSection4(false);
    }
    else if (this.current_phase == WalkController.HOW_MANY_STEPS) {
      this.showSection1(true);
      this.showSection3(true);
      this.showSection4(false);
   }
    else if (this.current_phase == WalkController.IN_PROGRESS) {
      this.showSection1(true);
      this.showSection3(false);
      this.showSection4(true);
    }
    else if (this.current_phase == WalkController.RESULTS) {
      this.showSection1(true);
      this.showSection3(true);
      this.showSection4(true);
    }
  }

    showSection1(show) {
    if (show) {
      this.section1Target.classList.remove("blur-sm");
    } else {
      this.section1Target.classList.add("blur-sm")
    }
  }



  // start button
  showSection3(show) {
    if (show) {
      this.section3Target.classList.remove("blur-sm");
    } else {
      this.section3Target.classList.add("blur-sm")
    }
  }

  // result section
  showSection4(show) {
    if (show) {
      this.section4Target.classList.remove("invisible");
    } else {
      this.section4Target.classList.add("invisible")
    }
  }

}

